import React, { useState, useEffect } from 'react';
import './select-method.scss';
import { connect, useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CardContainer from '../../../components/CardContainer';
//import logo_ccb from '../../../assets/images/pagos/logo_ccb.jpg';
import clave from '../../../assets/images/pagos/clave.png';
import visa from '../../../assets/images/pagos/visa.png';
import mastercard from '../../../assets/images/pagos/mastercard.png';
import yapi_general from '../../../assets/images/pagos/yapi_general.png';

import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import { listMethods } from './methods';

import { Dialog, Button } from '@material-ui/core';
import { getRecurrency } from '../../../util/endpoint';

import RecurrencyComponent from '../../views/Recurrency/RecurrencyComponent';
import DisableRecurrency from '../../views/Recurrency/DisableRecurrency';
import {  showRecurrencyComponents } from '../../../appRedux/actions/Recurrency';
import Formsecure from '../../../components/3DSecure';
import axios from 'axios';
import { getCookie } from "../../../util/util";

//const qs = require('querystring')
const SelectMethod = (props) => {
    const location = useLocation();
    const currentURL = location.pathname;
    let ppp = 'ppp';
    if (props.match.path.indexOf('auth') > -1) {
        ppp = '';
    }
    const [loading, setLoading] = useState(false);
    const [loadingerror, setLoadingerror] = useState(false);
    const [checked, setChecked] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [minvalue] = useState(props.pagosInfo.total);

    const [metodosPagos, setMetodosPagos] = useState([])
    const [loadingMetodosPagos, setLoadingMetodosPagos] = useState(true)
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        city: '',
        state: '',
        zip: '',
        address: '',
        // country: '',
    });
    const [formControls, setFormControls] = useState({
        c_emp_: 'JP',
        cardNumber: "",
        expirationDate_y: "2020",
        expirationDate_m: "01",
        expirationDate: "",
        cardCode: "",
        amount: props.pagosInfo.total,
        cuenta: props.pagosInfo.cuenta,
        n_ide: "",
        email: "",
        firstName: "",
        lastName: "",
        n_ide_tarjeta: "",
        api_key: "d47c29cfdf8e2456ac678c51f9e4ddfa8ec577f64e98aa9e863399f6a10210d4",
        pais: "PA",
        customerIP: "181.135.166.136",
        idMessageIn: false,
        MessageIn: '',
        typeMessage: '',
        loadingPay: false
    });
   // Deshabilita clic derecho y combinaciones para abrir consola de desarrollador en el navegador
   useEffect(() => {
    const handleKeyDown = (event) => {
        if (
        event.keyCode === 123 || // F12
        (event.ctrlKey && event.shiftKey && event.keyCode === 73) // Ctrl+Shift+I
        ) {
        event.preventDefault();
        }
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    window.addEventListener('keydown', handleKeyDown);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('contextmenu', handleContextMenu);
    };
}, []);

    useEffect(() => {
        let isMounted = true;
        listMethods().then(data => {
            if (isMounted) {
                setMetodosPagos(data);
                setLoadingMetodosPagos(false)
            }
        });
        return () => { isMounted = false };
    }, []);

    useEffect(() => {
        (async () => {

            const script = document.createElement('script');
            script.src = "https://secure.nmi.com/token/Collect.js";
            script.dataset.tokenizationKey = process.env.REACT_APP_RECAUDO_EXTERNO_TOKENIZATION_KEY;
            script.dataset.buttonText = 'Enviar pago';
            script.dataset.instructionText = 'Ingrese la información de pago'
            script.async = true;

            const script1 = document.createElement('script');
            script1.src = 'https://secure.nmi.com/js/v1/Gateway.js';
            script1.async = true;
            script1.onload = () => {
        };

            document.body.appendChild(script1); 
            document.body.appendChild(script);
            })();
        }, []);


        let dispatch = useDispatch();
        let selectorUrl = useSelector(state => state.recurrency)
        let token = getCookie('token');
        function setRecurrency(data) {
            dispatch(showRecurrencyComponents("loading"))
            getRecurrency(selectorUrl.recurrencia,'estado_pagorecurrente','JP')
            .then(res =>{
                axios.post(
                    res,
                    {
                        company:'JP'
                    },
                    { headers: { 'Authorization': `Bearer ${token}` }
                    }).then(res=>{
                        console.log(res.data)
                        if(res.data !== true) {
                            dispatch(showRecurrencyComponents(true))
                        } else {
                            dispatch(showRecurrencyComponents(false))
                        }
                    }).catch(err => {
                        dispatch(showRecurrencyComponents("loading"))
                    })
            })
        }

    useEffect(() => {
        setRecurrency()       
    },[])

    let handleSubmit = (event) => {
        const gateway = window.Gateway.create('checkout_public_N5XqMdSgHbGkcW2NSRSunQwv89RKYu2S');
        const threeDS = gateway.get3DSecure();
        if (formData.firstName === '' || formData.lastName === '' || formData.email === ''|| formData.phone === '' || formData.city === ''|| formData.state === ''|| formData.address === ''|| formData.zip === '') {
            return;}
        event.preventDefault();
        window.CollectJS.configure({
            variant: 'lightbox',
            callback: (e) => {
            const options = {
                paymentToken: e.token,
                currency: 'USD',
                amount: props.pagosInfo.total,
                email: formData.email,
                phone: formData.phone,
                city: formData.city,
                state: formData.state,
                address1: formData.address,
                country: "US",
                firstName: formData.firstName,
                lastName:formData.lastName,
                postalCode: formData.zip,
            };

            const threeDSecureInterface = threeDS.createUI(options);
            threeDSecureInterface.start('#threeDSMountPoint');
            threeDSecureInterface.on('challenge', function (e) {
                Swal.fire({
                    title: 'La transacción no tuvo éxito.',
                    text: 'Por su seguridad, el banco no permite realizar pagos con esta tarjeta. Le recomendamos intentar la transacción con otra tarjeta.',
                    icon: 'error',
                    showCancelButton: false
                })
                setFormData(
                            {
                                ...formData,
                                loadingPay: true
                            })
            });
            threeDSecureInterface.on('complete', function (e) {
                fetch('https://prd.appsjamar.com/cartera/dsecure/process_payment/', {
                    method: 'POST',
                    body: JSON.stringify({
                        ...options,
                        cavv: e.cavv,
                        xid: e.xid,
                        eci: e.eci,
                        cardHolderAuth: e.cardHolderAuth,
                        threeDsVersion: e.threeDsVersion,
                        directoryServerId: e.directoryServerId,
                        cardHolderInfo: e.cardHolderInfo,
                        cedula: props.pagosInfo.n_ide,
                    }),
                    })
                    .then(response => response.json())
                    .then(data => {
                        // Maneja la respuesta JSON aquí
                        let mensaje ='Por favor, verifique si tiene fondos suficientes en su cuenta. Si tiene suficientes fondos disponibles, intente nuevamente dentro de las próximas 24 horas. Si continúa teniendo problemas, comuníquese con nuestro servicio de atención al cliente para obtener ayuda adicional'
                        if (data.Success=== false) {
                            Swal.fire({
                                title: 'La transacción no tuvo éxito.',
                                text: mensaje,
                                icon: 'error',
                                showCancelButton: false
                            })
                        }else{
                            if (data.Success=== true) {
                            Swal.fire({
                                title: '¡Exito!',
                                text: 'Su pago a sido aplicado exitosamente',
                                icon: 'success',
                                showCancelButton: false
                            })
                            }else{
                                Swal.fire({
                                    title: 'La transacción no tuvo éxito.',
                                    text: 'Por favor, intente desde otro dispositivo' ,
                                    icon: 'error',
                                    showCancelButton: false
                                })
                            }
                    }
                    })
                    .catch(error => {
                        // Maneja los errores de la solicitud aquí
                        console.error('Error en la solicitud:', error);
                    });
            });

            threeDSecureInterface.on('failure', function (e) {
                console.log('failure');
            });

            threeDSecureInterface.on('error', function (e) {
                Swal.fire({
                    title: 'La transacción no tuvo éxito.',
                    text: 'Por favor, recargue la página y vuelva a intentarlo.',
                    icon: 'error',
                    showCancelButton: false
                })
                setFormData(
                            {
                                ...formData,
                                loadingPay: true
                            })
            });
            },
            
        });
        
        window.CollectJS.startPaymentRequest();
    }    

    const toggleForm = () => {
        setShowForm(!showForm);
      };

    const changeHandler = (event) => {
        props.pagosInfo.total = event.target.value;
        setFormControls(
            {
                ...formControls,
                amount: event.target.value
            })
    }

    const handleChange = () => {
        setChecked(!checked);
        setFormControls(
            {
                ...formControls,
                amount: minvalue
            })
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

    const hambrePayment = (data) => {
        window.location.href = data;
    };

    useEffect(() => {
        if (Object.keys(props.pagosInfo).length === 0) {
            if (props.match.path.indexOf('auth') > -1) {
                props.history.push(`/auth/pay`);
            } else {
                props.history.push(`/pagos`);
            }
        }
    });

    return (
        <div >
            <div className={"centrar " + ppp} >
                <div className={"card-content max-960 "}>
                    <CardContainer style={{ marginTop: '0px !important' }} cardTitle="Datos del pago">
                        <PerfectScrollbar>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-7 col-8">
                                        <label className="card-title-desc">Nombre de usuario</label>
                                        <div className="card-title-subdesc" >Sr(a) {props.pagosInfo.first_name ? props.pagosInfo.first_name : props.pagosInfo.nombre} {props.pagosInfo.last_name} </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-5 col-4">
                                        <label className="card-title-desc">No. de cuenta:  </label>
                                        <div className="card-title-subdesc" ><h6>{props.pagosInfo.cuenta} </h6> </div>


                                    </div>
                                </div>

                            </div>
                        </PerfectScrollbar>
                    </CardContainer >
                </div >

            </div>
            {(currentURL !== "/pagos/select-method" && props.pagosInfo.total > 0) &&
                <RecurrencyComponent history={props.history} />
            }
            
            {/* {props.pagosInfo.total > 0  && <RecurrencyComponent history={props.history} /> }  */}

            <div className={"centrar " + ppp} >
                <div className={"card-content max-960 mt-4"}>
                    <CardContainer className="mb15" cardTitle="Seleccione metodo de pago">
                        <PerfectScrollbar>

                            <div className="col-12">

                                <div className="row">

                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                        <label className="otrovalor card-title-desc" >
                                            <input
                                                type="checkbox"
                                                checked={!checked}
                                                onChange={handleChange}
                                                className="inputotrovalor"
                                            />
                                            <div className="pagavalor card-title-subdesc">
                                                Pago minimo: <span className="valorpagar">$ {minvalue}</span>
                                            </div>
                                        </label>
                                        <label className="otrovalor card-title-desc" >
                                            <input
                                                type="checkbox"
                                                checked={checked}
                                                onChange={handleChange}
                                                className="inputotrovalor"
                                            />
                                            <div className="pagavalor card-title-subdesc">
                                                Otro monto
                                            </div>
                                        </label>
                                    </div>
                                </div>

                                {checked === false ? '' :
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="card-title-subdesc" >
                                                Monto a pagar: <input type="number" className="form-control input-nobor inputvalue " id="IDENT"
                                                    aria-describedby="Cantidad" placeholder="100.05" value={formControls.amount} onChange={changeHandler} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <br />

                                {showForm && <Formsecure handleFormChange={handleFormChange} formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} /> }
                                <hr width="100%" color="#EDECEC" size="7" />


                                <div className="row">

                                    {loadingMetodosPagos ?
                                        <div className='col-12'> 
                                            <div className="text-center p30">
                                                <div className="text-center">
                                                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                </div>
                                                <div>
                                                    Cargando medios de pago...
                                                </div>
                                            </div>
                                        </div>
                                        :

                                        <div className="col-12">
                                            <div className="row justify-content-between">

                                                {metodosPagos.map(i => {
                                                    return (

                                                        i.n_ide === '9928' && i.canal === 'T_CREDITO' ?
                                                            formControls.loadingPay === false ?
                                                                <div className="col-sm-12 col-lg-5 col-md-6 inputotrovalor" onClick={async (e) => {
                                                                    if (formControls.amount < i.valor_minimo) {
                                                                        Swal.fire({
                                                                            title: 'Valor a pagar invalido',
                                                                            text: 'El valor minimo a pagar es de ' + i.valor_minimo + ' dolares.',
                                                                            icon: 'error',
                                                                            showCancelButton: false
                                                                        })
                                                                        return false;
                                                                    }
                                                                    // handleSubmit(e)
                                                                    
                                                                }} >
                                                                    <div className="center text-center  boton-select" onClick={toggleForm}>
                                                                        <div className="mpimgcontainer">
                                                                            <img className="mpimg" src={i.img} alt="Credit card" />
                                                                        </div>
                                                                        <div className="mptext">
                                                                            {i.title}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                : <div className="col-sm-8 col-xs-10 col-lg-4 col-md-6" >
                                                                    <div className="col-12 center text-center">
                                                                    </div>
                                                                </div>
                                                            :
                                                            <div className="col-sm-12 col-lg-5 col-md-6 inputotrovalor" onClick={async () => {
                                                                if (formControls.amount < 0) {
                                                                    Swal.fire({
                                                                        title: 'Valor a pagar invalido',
                                                                        text: 'El valor minimo a pagar es de ' + i.valor_minimo + ' dolares.',
                                                                        icon: 'error',
                                                                        showCancelButton: false
                                                                    })
                                                                    return false;
                                                                }

                                                                try {
                                                                    setLoading(true);
                                                                    const payMents = await i.action(props.pagosInfo.total, i.redirect, props.pagosInfo.n_ide)
                                                                    let url = '';
                                                                    if (i.title === 'Yappy') {
                                                                        url = payMents.data.url;
                                                                    } else {
                                                                        const jsonResponse = JSON.parse(payMents.data[0]);
                                                                        url = jsonResponse.data.url;
                                                                    }
                                                                    hambrePayment(url);
                                                                    setLoading(false);

                                                                } catch (error) {
                                                                    setLoading(false);
                                                                    Swal.fire({
                                                                        title: 'Error de conexion!',
                                                                        text: 'Ha ocurrido un error con el banco, intentelo nuevamente',
                                                                        icon: 'error',
                                                                        showCancelButton: false
                                                                    })

                                                                }

                                                            }}>
                                                                <div className="center text-center  boton-select">
                                                                    <div className="mpimgcontainer">
                                                                        <img className="mpimg" src={i.img} alt="Credit card"/>
                                                                    </div>
                                                                    <div className="mptext">
                                                                        {i.title}
                                                                        
                                                                    </div>

                                                                </div>
                                                            </div>

                                                    )

                                                })
                                                }

                                                {/*        <div className="col-sm-8 col-xs-10 col-lg-6 col-md-8 boton-select" onClick={() => {
                                                props.history.push(`/pagos/pay-panama`);
                                            }} >
                                                <div className="col-12 center text-center">
                                                    <h5>NEQUI</h5>
                                                </div>
                                            </div> */}



                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </CardContainer >
                </div >
            </div>



            <div className={"centrar " + ppp} >
                <div className={"card-content max-960 mt-4"}>
                    <CardContainer style={{ marginTop: '0px !important' }}>
                        <PerfectScrollbar>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-12 content-inputs">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <label className="card-title-desc">Aceptamos los siguientes metodos de pago:</label>
                                            </div>
                                        </div>
                                        <div className="row imgsmps">
                                            <img className="mpimgs" src={clave} alt="tarjeta clave" />
                                            <img className="mpimgs" src={visa} alt="visa" />
                                            <img className="mpimgs" src={mastercard} alt="mastercard" />
                                            <img className="mpimgs" src={yapi_general} alt="yappi" />
                                        </div>


                                    </div>


                                </div>
                            </div>
                        </PerfectScrollbar>
                    </CardContainer >
                </div >

            </div>
            
            <Dialog open={loading}>
                <div className="text-center p30">
                    <div className="text-center">
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    </div>
                    <div>
                        Validando...
                    </div>
                </div>

            </Dialog>
            {(currentURL !== "/pagos/select-method" && props.pagosInfo.total && props.recurrency.showRecurrency > 0) &&
                <DisableRecurrency />
            }
            {/* {props.pagosInfo.total > 0 && props.recurrency.showRecurrency === false } */}

            <Dialog open={loadingerror}>
                <div className="text-center p30">
                    <div className="text-center">
                        <p>Ha ocurrido un error, vuelva a intentarlo mas tarde</p>
                    </div>
                    <div className="text-center mt2">
                        <Button variant="contained" onClick={() => setLoadingerror(false)} >OK</Button>
                    </div>
                </div>

            </Dialog>
            <div id='threeDSMountPoint'></div>

        </div>




    )

}

const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(SelectMethod);
